import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import AboutPage from '@/views/AboutPage.vue';
import WorkPage from '@/views/WorkPage.vue';
import QuizPage from '@/views/QuizPage.vue';

// Vue 3 uses createRouter
const router = createRouter({
  history: createWebHistory(),  // Enable history mode
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
    },
    {
      path: '/cv',
      name: 'CV',
      component: AboutPage,
    },
    {
      path: '/work',
      name: 'Work',
      component: WorkPage,
    },
    {
      path: '/quiz',
      name: 'Quiz',
      component: QuizPage,
    }
  ],
});

export default router;
