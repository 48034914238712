<template>
  <div class="quiz-container">
    <h1 class="quiz-title">Music Quiz</h1>
    <select class="text-black" v-model="selectedGenre" @change="fetchSongs">
      <option disabled value="">Select a Genre</option>
      <option value="rock">Rock</option>
      <option value="pop">Pop</option>
      <option value="metal">Metal</option>
      <option value="hip-hop">Hip-Hop</option>
      <option value="dance">Dance</option>
      <option value="eighties">80s</option>
      <option value="nineties">90s</option>
      <option value="twothousands">00s</option>
    </select>
    <div class="iframe-wrapper">
      <p class="flex justify-center items-center h-full 
            text-xl font-bold tracking-wide text-white" v-if="!selectedGenre">
        Make your selection
      </p>      
      
      <div class="iframe-container" v-if="currentSong">
        <div v-if="isLoading" class="loading-message">
          <p class="text-white text-sm">Loading song...</p>
          <div class="spinner"></div>
        </div>
        <iframe 
          :src="currentSong" 
          allow="encrypted-media"
          @load="isLoading = false"
        ></iframe>
      </div>
    </div>

    <button 
      v-if="currentSong && !revealDetails" 
      @click="revealDetails = true" 
      class="reveal-button"
    >
      Reveal Song Details
    </button>

    <div v-if="revealDetails" class="song-details">
      <p><strong>Song:</strong> {{ songList[currentIndex].name }}</p>
      <p><strong>Artist:</strong> {{ songList[currentIndex].artist }}</p>
      <img :src="songList[currentIndex].artwork" alt="Album artwork" class="album-art">
      <button @click="nextSong">Next Song</button>
    </div>

    <p class="footer-text">For Livia</p>

  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: 'QuizPage',
  data() {
    return {
      isLoading: true,
      isStarted: false,
      selectedGenre: '',
      songList: [],
      currentIndex: 0,
      revealDetails: false,
      accessToken: ''
    }
  },
  computed: {
    currentSong() {
      return this.songList.length > 0 
        ? `https://open.spotify.com/embed/track/${this.songList[this.currentIndex].id}` 
        : '';
    }
  },
  methods: {
    startQuiz() {
      this.isStarted = true;
      // Create AudioContext after user interaction
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      if (audioContext.state === 'suspended') {
        audioContext.resume();
      }
    },

    async fetchAccessToken() {
      const API_ENDPOINT = "https://i0xqxhe98i.execute-api.us-east-1.amazonaws.com/prod/auth";
  
      try {
        const response = await axios.get(API_ENDPOINT);
        const body = JSON.parse(response.data.body);
        this.accessToken = body.token;
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    },
    
    async fetchSongs() {
      if (!this.accessToken) {
        await this.fetchAccessToken();
      }

      const searchQuery = (() => {
        switch(this.selectedGenre) {
          case 'eighties': return 'year:1980-1989';
          case 'nineties': return 'year:1990-1999';
          case 'twothousands': return 'year:2000-2009';
          default: return `genre:${this.selectedGenre}`;
        }
      })();

      const response = await fetch(`https://api.spotify.com/v1/search?q=${searchQuery}&type=track&limit=50&popularity=90`, {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`
        }
      });

      const data = await response.json();
      this.songList = data.tracks.items.map(track => ({
        id: track.id,
        name: track.name,
        artist: track.artists[0].name,
        artwork: track.album.images[0].url
      }))
      .sort(() => Math.random() - 0.5); // This shuffles the array

      this.currentIndex = 0;
      this.revealDetails = false;
    },
    nextSong() {
      if (this.currentIndex < this.songList.length - 1) {
        this.currentIndex++;
        this.revealDetails = false;
      } else {
        alert('Quiz completed!');
      }
    }

  }
}
</script>


<style scoped>
.quiz-title {
  font-size: 2.6rem;
  font-weight: bold;
  background: linear-gradient(
    300deg,
    cyan 0%,
    magenta 40%,
    yellow 60%,
    white 100%
  );
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 4s linear infinite;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.quiz-container {
  font-family: 'Roboto', system-ui, sans-serif;
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 20px;
  min-height: 90vh;
  min-height: 90vh;
  width:100%;
  max-width:400px;
  border-radius: 6px;

}

select, button {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  width: 100%;
}

button {
  background-color: magenta;
  color: white;
}

.iframe-container {
  position: relative;
  width: 100px;
  height: 90px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 10px;
}

iframe {
  position: absolute;
  top: 0;
  left: -281px;
  width: 373px;
  height: 88px;
  border: none;
}

.song-details {
  margin-top: 10px;
  font-size: 18px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

/* Add this to your existing styles */
.start-button {
  padding: 15px 30px;
  font-size: 20px;
  background-color: cyan;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 20px;
}

.start-button:hover {
  background-color: yellow;
}

/* Add to existing styles */
.play-instruction {
  margin-top: 10px;
  font-size: 14px;
  color: #9ca3af;
}

/* Add to existing styles */
.reveal-button {
  background-color: magenta;
  margin-top: 20px;
}

.album-art {
  width: 180px;
  height: 180px;
  border-radius: 8px;
  margin: 10px auto;
  display: block;
}
.iframe-wrapper {
  position: relative;
  width: 100%;
  height: 90px;
  overflow: hidden;
  margin: 0 auto;
  padding:5px;
  border-radius: 6px;
  background: linear-gradient(
    45deg,
    yellow,
    magenta,
    cyan,
    white
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.footer-text {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75rem;
  font-weight: 500;
  background: linear-gradient(
    90deg,
    cyan,
    magenta
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
}

.loading-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  margin: 8px auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
