<template>
  <button @click="$router.push('/quiz')"
    class="bg-white 
    text-black 
    dark:bg-black 
    dark:text-white 
    text-xs py-2 rounded-lg border
    shadow-md px-2 h-12">
    
    <svg xmlns="http://www.w3.org/2000/svg" 
     class="w-6 h-6" 
     fill="none" 
     viewBox="0 0 24 24" 
     stroke="currentColor">
  <path stroke-linecap="round" 
        stroke-linejoin="round" 
        stroke-width="2" 
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
  </button>
</template>

  